import * as React from 'react';
import { audiLightTheme } from '@audi/audi-ui-react-v2';
import styled from 'styled-components';
import { addWidthParameter } from '@oneaudi/feature-app-utils';
import { AudiStageLargeImageProps } from './interfaces';

const Image = styled.img`
  height: 100%;
  object-fit: cover;
  position: relative;
  width: 100%;
`;

const MediaItem = styled.div`
  height: 100%;
  position: absolute;
  width: 100%;
`;

const {
  l: breakpointL,
  m: breakpointM,
  xl: breakpointXL,
  xxl: breakpointXXL,
} = audiLightTheme.breakpoints;

const AudiStageLargeImage: React.FC<AudiStageLargeImageProps> = (props) => {
  const { imageAlt, imageNarrow, imageWide } = props;

  return (
    <MediaItem>
      <picture>
        {/* < 768 */}
        <source
          media={`(max-width: ${breakpointM}px) and (max-aspect-ratio: 3/4)`}
          srcSet={addWidthParameter(imageNarrow, breakpointM)}
        />
        <source
          media={`(max-width: ${breakpointM}px) and (min-aspect-ratio: 3/4)`}
          srcSet={addWidthParameter(imageWide, breakpointM)}
        />
        {/* < 1024 */}
        <source
          media={`(max-width: ${breakpointL}px) and (max-aspect-ratio: 3/4)`}
          srcSet={addWidthParameter(imageNarrow, breakpointL)}
        />
        <source
          media={`(max-width: ${breakpointL}px) and (min-aspect-ratio: 3/4)`}
          srcSet={addWidthParameter(imageWide, breakpointL)}
        />
        {/* < 1440 */}
        <source
          media={`(max-width: ${breakpointXL}px) and (max-aspect-ratio: 3/4)`}
          srcSet={addWidthParameter(imageNarrow, breakpointXL)}
        />
        <source
          media={`(max-width: ${breakpointXL}px) and (min-aspect-ratio: 3/4)`}
          srcSet={addWidthParameter(imageWide, breakpointXL)}
        />
        {/* >= 1440 */}
        <source
          media={`(min-width: ${breakpointXL}px) and (max-aspect-ratio: 3/4)`}
          srcSet={addWidthParameter(imageNarrow, breakpointXXL)}
        />
        <source
          media={`(min-width: ${breakpointXL}px) and (min-aspect-ratio: 3/4)`}
          srcSet={addWidthParameter(imageWide, breakpointXXL)}
        />
        {/* fallback */}
        <Image alt={imageAlt} data-object-fit src={addWidthParameter(imageWide, breakpointM)} />
      </picture>
    </MediaItem>
  );
};

export default AudiStageLargeImage;
