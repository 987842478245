import { Asset, ContentReference } from '@oneaudi/falcon-tools';
import { AudiStageLargeContent, HTMLElementFromAudiReactUI } from './components/interfaces';

/* eslint-disable camelcase */
export interface FalconContent {
  __type: 'aem-headless';
  fields: {
    headlineText: null;
    headlineHtmlTag: HTMLElementFromAudiReactUI;
    sublineText?: string;
    primaryButton_linkUrl: string;
    primaryButton_linkTarget: '_blank' | '_self';
    primaryButton_linkText: string;
    secondaryButton_linkUrl: string;
    secondaryButton_linkTarget: '_blank' | '_self';
    secondaryButton_linkText: string;
    carlineModel?: string;
    emissionLabel?: string;
    emissionValue?: string;
    consumptionLabel?: string;
    consumptionValue?: string;
    eecDisclaimerText?: string;
    isStageOnTop?: boolean;
    alignRight?: boolean;
    alignTop?: boolean;
    theme: 'dark' | 'light';
    topShader?: boolean;
    bottomShader?: boolean;
    hideEec?: boolean;
    imageWideScreen?: ContentReference;
    imageWideScreen_imageAlt?: string;
    imageWideScreen_assetDisclaimer?: string;
    imageNarrowScreen?: ContentReference;
    imageNarrowScreen_imageAlt?: string;
    imageNarrowScreen_assetDisclaimer?: string;
    videoWideScreen?: ContentReference;
    videoWideScreen_poster?: ContentReference;
    videoWideScreen_videoAlt?: null;
    videoWideScreen_autoplay?: boolean | null;
    videoWideScreen_loop?: boolean | null;
    videoWideScreen_mute?: boolean | null;
    videoWideScreen_assetDisclaimer?: null;
    videoNarrowScreen?: ContentReference;
    videoNarrowScreen_poster?: ContentReference;
    videoNarrowScreen_videoAlt?: null;
    videoNarrowScreen_autoplay?: boolean | null;
    videoNarrowScreen_loop?: boolean | null;
    videoNarrowScreen_mute?: boolean | null;
    videoNarrowScreen_assetDisclaimer?: null;
  };
}

export type EditorJsonContent = {
  __type?: 'editor.json';
} & AudiStageLargeContent;

export function mapContent(content: EditorJsonContent | FalconContent): EditorJsonContent {
  if (content === undefined || content === null) return;
  if (content.__type !== 'aem-headless') {
    return content as EditorJsonContent;
  }

  const { fields } = content;
  const headline = fields.headlineText
    ? {
        headlineText: fields.headlineText,
        headlineHtmlTag: fields.headlineHtmlTag,
      }
    : null;

  const subline = fields.sublineText
    ? {
        sublineText: fields.sublineText,
      }
    : null;

  const primaryButton = fields.primaryButton_linkUrl
    ? {
        linkUrl: fields.primaryButton_linkUrl,
        linkTarget: fields.primaryButton_linkTarget,
        linkText: fields.primaryButton_linkText,
      }
    : null;

  const secondaryButton = fields.secondaryButton_linkUrl
    ? {
        linkUrl: fields.secondaryButton_linkUrl,
        linkTarget: fields.secondaryButton_linkTarget,
        linkText: fields.secondaryButton_linkText,
      }
    : null;

  const carline = fields.carlineModel
    ? {
        carlineModel: fields.carlineModel,
      }
    : null;

  const emissions = fields.emissionValue
    ? {
        emissionLabel: fields.emissionLabel,
        emissionValue: fields.emissionValue,
      }
    : null;

  const consumption = fields.consumptionValue
    ? {
        consumptionLabel: fields.consumptionLabel,
        consumptionValue: fields.consumptionValue,
      }
    : null;

  const eecDisclaimer = fields.eecDisclaimerText
    ? {
        eecDisclaimerText: fields.eecDisclaimerText,
      }
    : null;

  const imageWideScreen = {
    image: { assetValue: (fields.imageWideScreen as Asset).path },
    imageAlt: fields.imageWideScreen_imageAlt,
    assetDisclaimer: fields.imageWideScreen_assetDisclaimer,
  };
  const imageNarrowScreen = {
    image: { assetValue: (fields.imageNarrowScreen as Asset).path },
    imageAlt: fields.imageNarrowScreen_imageAlt,
    assetDisclaimer: fields.imageNarrowScreen_assetDisclaimer,
  };

  const videoWideScreen = fields.videoNarrowScreen
    ? {
        assetDisclaimer: fields.videoNarrowScreen_assetDisclaimer,
        video: { assetValue: (fields.videoNarrowScreen as Asset).path },
        poster: { assetValue: (fields.videoNarrowScreen_poster as Asset).path },
        videoAlt: fields.videoNarrowScreen_videoAlt,
        autoplay: fields.videoNarrowScreen_autoplay,
        mute: fields.videoNarrowScreen_mute,
        loop: fields.videoNarrowScreen_loop,
      }
    : null;

  const videoNarrowScreen = fields.videoNarrowScreen
    ? {
        assetDisclaimer: fields.videoNarrowScreen_assetDisclaimer,
        video: { assetValue: (fields.videoNarrowScreen as Asset).path },
        poster: { assetValue: (fields.videoNarrowScreen_poster as Asset).path },
        videoAlt: fields.videoNarrowScreen_videoAlt,
        autoplay: fields.videoNarrowScreen_autoplay,
        mute: fields.videoNarrowScreen_mute,
        loop: fields.videoNarrowScreen_loop,
      }
    : null;

  const normalizedContent: EditorJsonContent = {
    textElements: {
      headline,
      subline,
      primaryButton,
      secondaryButton,
    },
    consumptionEmissionContainer: {
      carline,
      emissions,
      consumption,
      eecDisclaimer,
    },
    appearance: {
      isStageOnTop: fields.isStageOnTop,
      alignRight: fields.alignRight,
      alignTop: fields.alignTop,
      theme: fields.theme,
      topShader: fields.topShader,
      bottomShader: fields.bottomShader,
      hideEec: fields.hideEec,
    },
    media: {
      images:
        imageWideScreen && imageNarrowScreen
          ? {
              imageWideScreen,
              imageNarrowScreen,
            }
          : null,
      videos:
        videoWideScreen && videoNarrowScreen
          ? {
              videoWideScreen,
              videoNarrowScreen,
            }
          : null,
    },
  };

  return normalizedContent;
}
