import { useEffect, useState } from 'react';

export const useWindowSize = (): {
  height: number;
  width: number;
} => {
  const [state, setstate] = useState({
    height: typeof window !== 'undefined' ? window.innerHeight : 768,
    width: typeof window !== 'undefined' ? window.innerWidth : 320,
  });

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    function handleResize(): void {
      setstate({
        height: typeof window !== 'undefined' ? window.innerHeight : 768,
        width: typeof window !== 'undefined' ? window.innerWidth : 320,
      });
    }

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', handleResize);
      return (): void => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, []);

  return state;
};
