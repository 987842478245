import { AssetBrowserProps } from '../components/interfaces';

export const parseImageAsset = (assetImage: AssetBrowserProps): string | null => {
  if (!assetImage) {
    return null;
  }

  try {
    const image = JSON.parse(assetImage.assetValue);
    try {
      return image.image[0].imageFormats['web-largest'];
    } catch (e2) {
      // e2: keep silent, when web-largest is not yet defined
      return image.image[0].fileInfo.fileSource;
    }
  } catch (e1) {
    // e1: keep silent, when image path is not a valid JSON
    return assetImage.assetValue;
  }
};
