import * as React from 'react';
import { Text, ThemeProvider, audiLightTheme } from '@audi/audi-ui-react-v2';
import { useImperativeHandle, useRef } from 'react';
import styled from 'styled-components';
import { AudiStageLargeInfoContentProps, ContainerProps, HeadlineWrapperProps } from './interfaces';
import AudiStageLargeButtonWrapper from './audi-stage-large-button-wrapper';
import FootnoteReplacer from './audi-stage-large-footnote-replacer';

const { m: breakpointM, xl: breakpointXL } = audiLightTheme.breakpoints;

const ContentWrap = styled.div`
  height: 100%;
  pointer-events: none;
  position: relative;
  padding: 0 4%;
  z-index: 4;
  @media (min-width: 1566px) {
    padding: 0 calc(calc(100% - 1440px) / 2);
  }
`;

const Container = styled.div<ContainerProps>`
  position: absolute;
  width: 92%;
  @media (max-width: ${breakpointM - 1}px) {
    ${({ alignTop, isVideo }): string =>
      !alignTop || isVideo ? 'bottom: 0; height: auto' : 'top: 0; height: 100%'};
  }
  @media (min-width: ${breakpointM}px) {
    ${({ alignTop }): string => (alignTop ? 'top: 0; height: 100%' : 'bottom: 0; height: auto')};
  }
  @media (min-width: ${breakpointXL}px) {
    width: auto;
    ${({ alignRight }): string => (alignRight ? 'right: 4%;' : '')};
  }
`;

const HeadlineSublineWrapper = styled.div<HeadlineWrapperProps>`
  ${({ alignTop, isVideo }): string =>
    !alignTop && isVideo ? 'text-align: left; margin-left: 0; margin-right: auto;' : ''};
  ${({ alignRight }): string =>
    alignRight ? 'text-align: right; margin-right: 0; margin-left: auto;' : ''};
  pointer-events: all;
`;

const AudiStageLargeInfoContent = React.forwardRef<
  {
    readonly wrapper: HTMLDivElement;
    readonly button: HTMLDivElement;
  },
  AudiStageLargeInfoContentProps
>((props, ref) => {
  const {
    textElements,
    alignRight,
    alignTop,
    theme,
    footnoteReferences,
    referenceServiceManager,
    isVideo,
  } = props;
  const wrapperRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLDivElement>(null);
  useImperativeHandle(ref, () => ({
    get button(): HTMLDivElement {
      return buttonRef.current;
    },
    get wrapper(): HTMLDivElement {
      return wrapperRef.current;
    },
  }));
  return (
    <ThemeProvider theme={theme}>
      <ContentWrap>
        <Container {...{ alignRight, alignTop, isVideo }} ref={wrapperRef}>
          <HeadlineSublineWrapper {...{ alignTop, alignRight, isVideo }}>
            {textElements.headline &&
              textElements.headline.headlineText &&
              textElements.headline.headlineHtmlTag && (
                <Text
                  as={textElements.headline.headlineHtmlTag}
                  spaceStackEnd="xs"
                  spaceStackStart="xl"
                  variant="order1"
                >
                  <FootnoteReplacer
                    content={textElements.headline.headlineText}
                    footnoteReferences={footnoteReferences}
                    referenceServiceManager={referenceServiceManager}
                  />
                </Text>
              )}
            {textElements.subline && textElements.subline.sublineText && (
              <Text as="h4" spaceStackEnd="l" variant="order4">
                <FootnoteReplacer
                  content={textElements.subline.sublineText}
                  footnoteReferences={footnoteReferences}
                  referenceServiceManager={referenceServiceManager}
                />
              </Text>
            )}
          </HeadlineSublineWrapper>
          {(textElements.primaryButton || textElements.secondaryButton) && (
            <AudiStageLargeButtonWrapper
              alignRight={alignRight}
              alignTop={isVideo ? false : alignTop}
              primaryButton={textElements.primaryButton}
              ref={buttonRef}
              secondaryButton={textElements.secondaryButton}
            />
          )}
        </Container>
      </ContentWrap>
    </ThemeProvider>
  );
});

AudiStageLargeInfoContent.displayName = 'AudiStageLargeInfoContent';

export default AudiStageLargeInfoContent;
