import * as React from 'react';

import { useEffect, useMemo, useRef, useState } from 'react';

import { AudiVideoServiceInterfaceV1 } from '@oneaudi/video-service';
import { FeatureAppLoader } from '@feature-hub/react';
import { audiLightTheme } from '@audi/audi-ui-react-v2';
import styled from 'styled-components';
import throttle from 'lodash.throttle';
import { parseVideoAppConfig } from '../utils/parse-video-app-config';
import { VideosProps } from './interfaces';
import { useWindowSize } from './hooks';

interface AudiStageLargeVideoProps {
  videoFeatureAppVersion: string;
  videoService?: AudiVideoServiceInterfaceV1;
  videofeatureAppId: string;
  videos: VideosProps;
}

const VideoWrapper = styled.div`
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;

const AudiStageLargeVideo: React.FC<AudiStageLargeVideoProps> = (props) => {
  const { videoFeatureAppVersion, videofeatureAppId, videos, videoService } = props;
  // eslint-disable-next-line no-console
  const { width } = useWindowSize();
  const { m: breakpointM } = audiLightTheme.breakpoints;
  const videoRef = useRef<HTMLDivElement>(null);
  const videofeatureAppConfig = useMemo(() => parseVideoAppConfig(videos), [videos]);
  const [scrollY, setScrollY] = useState<number>(0);
  const [, setIsInViewport] = useState<boolean | null>(null);
  const [isAutoplay] = useState<boolean>(() => {
    return width < breakpointM
      ? videofeatureAppConfig.videos.xs.autoPlay
      : videofeatureAppConfig.videos.m.autoPlay;
  });
  const [isPlaying, setIsPlaying] = useState<boolean>(() => {
    return isAutoplay;
  });

  const setVideoEvents = (): void => {
    const video = videoRef.current.querySelector('video');
    if (!video) {
      console.error('video element not found!');
      return null;
    }

    video.addEventListener('play', () => {
      setIsPlaying(true);
    });
    video.addEventListener('ended', () => {
      setIsPlaying(false);
    });
  };

  useEffect(function onMount() {
    // skip if ssr
    /* istanbul ignore next */
    if (!document || !window) {
      return;
    }

    // execute `onScroll` max. every 100 milliseconds
    const onScroll = throttle(() => setScrollY(window.scrollY), 100);

    document.addEventListener('scroll', onScroll);

    return (): void => {
      document.removeEventListener('scroll', onScroll);
    };
  }, []);

  useEffect(() => {
    // skip ssr and loading state
    if (videoRef.current === null || !window || !videoService) {
      return;
    }

    const rect = videoRef.current.getBoundingClientRect();
    const outTop = rect.top + rect.height < 0;
    const outBottom = rect.top > window.innerHeight;
    const isInViewport_ = !outTop && !outBottom;

    setIsInViewport((wasInViewport) => {
      // case: initial value => return first "real" value
      // and don't interfere with video settings like `autoplay`

      /* istanbul ignore next */
      if (wasInViewport === null) {
        return isInViewport_;
      }

      // https://github.com/testing-library/react-testing-library/issues/671
      /* istanbul ignore next */
      (async (): Promise<void> => {
        try {
          // case: video scrolls out
          if (wasInViewport && !isInViewport_ && isPlaying) {
            videoService.stop(videofeatureAppId);
            setIsPlaying(false);
            setTimeout(() => {
              const videoElement = videoRef.current.querySelector('video');
              if (videoElement && videoElement.currentTime >= 0) {
                videoElement.currentTime = 0;
              }
            }, 200);
          }

          // case: video scrolls in
          if (!wasInViewport && isInViewport_ && isAutoplay) {
            await videoService.play(videofeatureAppId);
          }
        } catch (error) {
          // play failed due to browser policies:
          // Safari: https://developer.apple.com/documentation/webkit/delivering_video_content_for_safari
          // Chrome: https://goo.gl/xX8pDD
        }
      })();

      /* istanbul ignore next */
      return isInViewport_;
    });
  }, [isPlaying, isAutoplay, scrollY, videoService, videofeatureAppId]);

  return (
    <VideoWrapper onLoad={setVideoEvents} ref={videoRef}>
      <FeatureAppLoader
        config={videofeatureAppConfig}
        featureAppId={videofeatureAppId}
        serverSrc={`https://fa-video-player.cdn.prod.collab.apps.one.audi/${videoFeatureAppVersion}/fh/app.node.js`}
        src={`https://fa-video-player.cdn.prod.collab.apps.one.audi/${videoFeatureAppVersion}/fh/app.js`}
      />
    </VideoWrapper>
  );
};

export default AudiStageLargeVideo;
