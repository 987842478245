/* istanbul ignore file */
/* eslint-disable @typescript-eslint/no-unused-vars */
// WebPack generation of HTML files under `/demo`

import * as React from 'react';

import { AudiFootnoteRefernceServiceScopeManagerInterfaceV3 } from '@oneaudi/footnote-reference-service';
import {
  ConsumptionsAndEmissions,
  VueFormatterServiceInterfaceV1,
} from '@oneaudi/vue-formatter-service';
import { FeatureAppDefinition, FeatureServices, Logger } from '@feature-hub/core';

import { AsyncSsrManagerV1 } from '@feature-hub/async-ssr-manager';
import { AudiPlatformProvider } from '@audi/audi-ui-react-v2';
import { AudiVideoServiceInterfaceV1 } from '@oneaudi/video-service';
import { ContentServiceV1 } from '@oneaudi/content-service';
import { CurrentCarlineServiceV1 } from '@oneaudi/current-carline-service';
import { LocaleServiceV1 } from '@volkswagen-onehub/locale-service';
import { ReactFeatureApp } from '@feature-hub/react';
import { SerializedStateManagerV1 } from '@feature-hub/serialized-state-manager';
import AudiStageLarge from './components/audi-stage-large';
import { consumptionEmissionContainerProps } from './components/interfaces';
import { mapContent } from './editor';

interface Dependencies extends FeatureServices {
  readonly 'audi-content-service': ContentServiceV1;
  readonly 'audi-current-carline-service': CurrentCarlineServiceV1 | undefined;
  readonly 'audi-footnote-reference-service': AudiFootnoteRefernceServiceScopeManagerInterfaceV3;
  readonly 'audi-video-service': AudiVideoServiceInterfaceV1 | undefined;
  readonly 'dbad:audi-vue-formatter-service': VueFormatterServiceInterfaceV1 | undefined;
  readonly 'locale-service': LocaleServiceV1;
  readonly 's2:async-ssr-manager': AsyncSsrManagerV1 | undefined;
  readonly 's2:serialized-state-manager': SerializedStateManagerV1 | undefined;
  readonly 's2:logger': Logger;
}

type FeatureAppDefinitionType = FeatureAppDefinition<
  ReactFeatureApp,
  Dependencies,
  Record<string, unknown>
>;

export const videoFeatureAppVersion = 'v5.1.2';

const featureAppDefinition: FeatureAppDefinitionType = {
  create: ({ featureServices, featureAppId }) => {
    const contentService = featureServices['audi-content-service'];
    const videoService = featureServices['audi-video-service'];
    const localeService = featureServices['locale-service'];
    const vueFormatterService = featureServices['dbad:audi-vue-formatter-service'];
    const carlineService = featureServices['audi-current-carline-service'];
    const referenceServiceScopeManager = featureServices['audi-footnote-reference-service'];

    const referenceServiceManager = referenceServiceScopeManager
      ? referenceServiceScopeManager.getDefaultScopeRefService()
      : undefined;

    const asyncSsrManager = featureServices['s2:async-ssr-manager'];
    const serializedStateManager = featureServices['s2:serialized-state-manager'];

    const combineManualComsumptionData = (
      content: Record<string, consumptionEmissionContainerProps>,
    ) => {
      if (
        content &&
        content.consumptionEmissionContainer &&
        content.consumptionEmissionContainer.consumption &&
        content.consumptionEmissionContainer.consumption.consumptionLabel &&
        content.consumptionEmissionContainer.consumption.consumptionLabel.trim() !== '' &&
        content.consumptionEmissionContainer.consumption.consumptionValue.trim() !== ''
      ) {
        return `${content.consumptionEmissionContainer.consumption.consumptionLabel}: ${content.consumptionEmissionContainer.consumption.consumptionValue}`;
      }
      return null;
    };

    const combineManualEmissionsData = (
      content: Record<string, consumptionEmissionContainerProps>,
    ) => {
      if (
        content &&
        content.consumptionEmissionContainer &&
        content.consumptionEmissionContainer.emissions &&
        content.consumptionEmissionContainer.emissions.emissionLabel &&
        content.consumptionEmissionContainer.emissions.emissionLabel.trim() !== '' &&
        content.consumptionEmissionContainer.emissions.emissionValue.trim() !== ''
      ) {
        return `${content.consumptionEmissionContainer.emissions.emissionLabel}: ${content.consumptionEmissionContainer.emissions.emissionValue}`;
      }
      return null;
    };

    let preRender = { content: null, vueWerte: null };

    if (asyncSsrManager) {
      if (typeof serializedStateManager !== 'undefined') {
        serializedStateManager.register(() => JSON.stringify(preRender));
      }
      let content = null;
      let carLine = null;
      if (contentService) {
        content = mapContent(contentService.getContent());
        preRender.content = content;
      }
      if (carlineService || content) {
        carLine =
          // eslint-disable-next-line no-nested-ternary
          carlineService && carlineService.carline && carlineService.carline !== ''
            ? carlineService.carline
            : content?.consumptionEmissionContainer?.carline?.carlineModel
              ? content.consumptionEmissionContainer.carline.carlineModel
              : null;
      }
      asyncSsrManager.scheduleRerender(
        (async (): Promise<ConsumptionsAndEmissions> => {
          if (carLine && carLine !== '' && vueFormatterService && localeService) {
            try {
              const vueWerte = await vueFormatterService.vueRangeForCarline(
                localeService.countryCode,
                localeService.language,
                carLine,
              );
              preRender.vueWerte = vueWerte;
              return vueWerte;
            } catch (error) {
              return null;
            }
          }
        })(),
      );
    } else {
      const serializedAudiHeaderData = serializedStateManager?.getSerializedState();
      if (serializedAudiHeaderData) {
        preRender = JSON.parse(serializedAudiHeaderData);
      }
    }
    return {
      // disable eslint rule because this is a (Feature Hub) React Feature App
      // that doesn't define a `displayName` attribute
      // eslint-disable-next-line react/display-name
      render: (): React.ReactNode => (
        <AudiPlatformProvider>
          <AudiStageLarge
            carlineService={carlineService}
            content={preRender.content}
            contentService={contentService}
            featureAppId={featureAppId}
            formattedConsumption={
              preRender.vueWerte?.formattedConsumption ||
              combineManualComsumptionData(preRender.content) ||
              undefined
            }
            formattedEmission={
              preRender.vueWerte?.formattedEmission ||
              combineManualEmissionsData(preRender.content) ||
              null
            }
            localeService={localeService}
            referenceServiceManager={referenceServiceManager}
            videoFeatureAppVersion={videoFeatureAppVersion}
            videoService={videoService}
            vueFormatterService={vueFormatterService}
            configProvider={undefined}
          />
        </AudiPlatformProvider>
      ),
    };
  },

  dependencies: {
    externals: {
      '@audi/audi-ui-react-v2': '^2',
      '@feature-hub/react': '^2.9.0',
      react: '^17.0.2 || ^18.2.0',
      'react-dom': '^17.0.2 || ^18.2.0',
      'styled-components': '^5.1.1',
    },
    featureServices: {
      'audi-content-service': '^1.0.0',
      'locale-service': '^1.0.0',
      's2:logger': '^1.0.0',
    },
  },

  optionalDependencies: {
    featureServices: {
      'audi-current-carline-service': '^1.0.0',
      'audi-footnote-reference-service': '3.0.0',
      'audi-video-service': '^1.0.0',
      'dbad:audi-vue-formatter-service': '^1.0.0',
      's2:async-ssr-manager': '^1.0.0',
      's2:serialized-state-manager': '^1.0.0',
    },
  },
};

export default featureAppDefinition;
