import * as React from 'react';

import { LayoutItem, Text } from '@audi/audi-ui-react-v2';
import { useEffect, useMemo, useState } from 'react';

import styled from 'styled-components';
import FootnoteReplacer from './audi-stage-large-footnote-replacer';
import { AudiStageLargeDisclaimerProps, DisclaimerWrapProps } from './interfaces';

const DisclaimerWrap = styled.div<DisclaimerWrapProps>`
  background-color: ${({ backgroundColor }): string => backgroundColor};
  overflow: hidden;
  padding: 0 4%;
  /* x = 100/92*1440 custom value – not in Theme available */
  @media (min-width: 1566px) {
    padding: 0 calc(calc(100% - 1440px) / 2);
  }
`;
const TextWrap = styled.div`
  @media (min-width: 1024px) {
    display: inline-block;
  }
`;

const AudiStageLargeDisclaimer: React.FC<AudiStageLargeDisclaimerProps> = (props) => {
  const {
    carlineService,
    consumptionEmissionContainer,
    referenceServiceManager,
    hideEec,
    assetDisclaimer,
    localeService,
    theme,
    vueFormatterService,
    footnoteReferences,
    formattedConsumption,
    formattedEmission,
  } = props;

  const [csrEmissionString, setcsrEmissionString] = useState('');
  const [csrConsumptionString, setcsrConsumptionString] = useState('');
  const [EditorialDisclaimerText, setEditorialDisclaimerText] = useState('');

  const currentCarlineFromService = carlineService ? carlineService.carline : '';
  const carlineFromJSON =
    consumptionEmissionContainer?.carline && consumptionEmissionContainer?.carline !== null
      ? consumptionEmissionContainer.carline.carlineModel
      : '';

  const backgroundColor = theme === 'dark' ? '#000' : '#fff';

  const emissionsString = useMemo(() => {
    if (consumptionEmissionContainer === null) {
      return '';
    }
    if (csrEmissionString !== '') {
      return csrEmissionString;
    }
    if (formattedEmission && formattedEmission !== '') {
      return formattedEmission;
    }
    if (consumptionEmissionContainer && consumptionEmissionContainer.emissions !== null) {
      return `${consumptionEmissionContainer.emissions.emissionLabel}: ${consumptionEmissionContainer.emissions.emissionValue}`;
    }
    return '';
  }, [formattedEmission, consumptionEmissionContainer, csrEmissionString]);

  const consumptionString = useMemo(() => {
    if (consumptionEmissionContainer === null) {
      return '';
    }
    if (csrConsumptionString !== '') {
      return csrConsumptionString;
    }
    if (formattedConsumption && formattedConsumption !== '') {
      return formattedConsumption;
    }
    if (consumptionEmissionContainer?.consumption !== null) {
      return `${consumptionEmissionContainer.consumption.consumptionLabel}: ${consumptionEmissionContainer.consumption.consumptionValue}`;
    }
    return '';
  }, [formattedConsumption, consumptionEmissionContainer, csrConsumptionString]);

  const carlineToFetch = useMemo(() => {
    if (currentCarlineFromService !== '' && (currentCarlineFromService !== undefined || null)) {
      return currentCarlineFromService;
    }
    if (carlineFromJSON !== null && carlineFromJSON !== '') {
      return carlineFromJSON;
    }
    return '';
  }, [currentCarlineFromService, carlineFromJSON]);

  useEffect(() => {
    if (carlineToFetch !== '' && vueFormatterService && localeService) {
      vueFormatterService
        .vueRangeForCarline(localeService.countryCode, localeService.language, carlineToFetch)
        .then((formattedValues) => {
          setcsrEmissionString(formattedValues.formattedEmission);
          setcsrConsumptionString(formattedValues.formattedConsumption);
        })
        .catch((error) => {
          setcsrEmissionString('');
          setcsrConsumptionString('');
          console.warn(
            `[audi-stage-large] Could not find carline "${carlineToFetch}". Original message: ${error.message}`,
          );
        });
    }
  }, [
    localeService,
    localeService.countryCode,
    localeService.language,
    vueFormatterService,
    carlineToFetch,
  ]);

  useEffect(() => {
    if (consumptionEmissionContainer) {
      const { eecDisclaimer } = consumptionEmissionContainer;
      if (eecDisclaimer) {
        const { eecDisclaimerText } = eecDisclaimer;
        const unescapedEecDisclaimerText = eecDisclaimerText
          .replaceAll('&lt;', '<')
          .replaceAll('&gt;', '>');
        setEditorialDisclaimerText(unescapedEecDisclaimerText);
      }
    }
  }, [consumptionEmissionContainer]);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {(!hideEec || assetDisclaimer) && (
        <DisclaimerWrap backgroundColor={backgroundColor}>
          <LayoutItem spaceStackEnd="xxl" spaceStackStart="s" data-testid="disclaimer-data">
            {assetDisclaimer && (
              <Text spaceStackEnd="xs" variant="copy2">
                <FootnoteReplacer
                  content={assetDisclaimer}
                  footnoteReferences={footnoteReferences}
                  referenceServiceManager={referenceServiceManager}
                />
              </Text>
            )}
            {!hideEec && (
              <>
                {emissionsString !== '' && (
                  <TextWrap data-testid="emissions-data">
                    <Text variant="copy2" spaceStackEnd="xs" spaceInlineEnd="l">
                      <FootnoteReplacer
                        content={emissionsString}
                        footnoteReferences={footnoteReferences}
                        referenceServiceManager={referenceServiceManager}
                      />
                    </Text>
                  </TextWrap>
                )}
                {consumptionString !== '' && (
                  <TextWrap data-testid="consumption-data">
                    <Text spaceStackEnd="xs" variant="copy2">
                      <FootnoteReplacer
                        content={consumptionString}
                        footnoteReferences={footnoteReferences}
                        referenceServiceManager={referenceServiceManager}
                      />
                    </Text>
                  </TextWrap>
                )}
                {EditorialDisclaimerText !== '' && (
                  <Text spaceStackEnd="xs" variant="copy2" data-testid="eec-disclaimer-data">
                    <FootnoteReplacer
                      content={EditorialDisclaimerText}
                      footnoteReferences={footnoteReferences}
                      referenceServiceManager={referenceServiceManager}
                    />
                  </Text>
                )}
              </>
            )}
          </LayoutItem>
        </DisclaimerWrap>
      )}
    </>
  );
};

export default AudiStageLargeDisclaimer;
