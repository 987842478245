import * as React from 'react';
import styled from 'styled-components';
import { AudiStageLargeShaderProps, ShaderBottomProps, ShaderTopProps } from './interfaces';

/* stylelint-disable */
const ShaderTop = styled.div<ShaderTopProps>`
  background: ${({ topBackgroundColor, transparentBackgroundColor }): string =>
    `linear-gradient(180deg, ${topBackgroundColor} 0%, ${transparentBackgroundColor} 40%)`};
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
`;

const ShaderBottom = styled.div<ShaderBottomProps>`
  background: ${({ bottomBackgroundColor, transparentBackgroundColor }): string =>
    `linear-gradient(${transparentBackgroundColor} 50%, ${bottomBackgroundColor} 100%)`};
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
`;
/* stylelint-enable */

const AudiStageLargeShader: React.FC<AudiStageLargeShaderProps> = (props) => {
  const { topShader, bottomShader, theme } = props;

  const topBackgroundColor = theme === 'dark' ? 'rgba(0,0,0,.7)' : 'rgba(255,255,255,.7)';
  const bottomBackgroundColor = theme === 'dark' ? 'rgba(0,0,0,1)' : 'rgba(255,255,255,1)';
  const transparentBackgroundColor = theme === 'dark' ? 'rgba(0,0,0,0)' : 'rgba(255,255,255,0)';

  return (
    <>
      {topShader && (
        <ShaderTop
          topBackgroundColor={topBackgroundColor}
          transparentBackgroundColor={transparentBackgroundColor}
        />
      )}
      {bottomShader && (
        <ShaderBottom
          bottomBackgroundColor={bottomBackgroundColor}
          transparentBackgroundColor={transparentBackgroundColor}
        />
      )}
    </>
  );
};

export default AudiStageLargeShader;
